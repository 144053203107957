.coins_watching {
  flex: 3;
}

.coins_watching h4 {
  font-size: 1.25rem;
  color: var(--primary);
  margin-bottom: 1rem;
  letter-spacing: 2px;
  font-weight: 500;
}


@media screen and (max-width: 1000px) {
  .coins_watching {
    width: 80%;
  }

  .coins_watching h4 {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .coins_watching {
    width: 100%;
  }

}