.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  background-color: var(--primary);
  cursor: pointer;
  padding: 15px 20px;
}

.modal_main {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.coin_info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin_info img {
  margin-right: .5rem;
}

.coin_info img {
  width: 24px;
}

.coin_info p {
  font-size: 1.5rem;
}

.balance {
  font-weight: 500;
  text-align: center;
  font-size: 2rem;
}