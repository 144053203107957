.market_info_container {    
  flex: 1;
}

h3 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--primary)
}

.market_info {
  display: flex;
  align-items: top;
  margin-top: 1rem;
  gap: 10px;
  flex-wrap: wrap;
}

.market_info_left, .market_info_right {
  flex: 1;
}

.market_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  margin-top: 0.5rem;  
}

.market_row p {
  font-weight: 300;
  line-height: 1.5;
  margin-right: 1.5rem;
}

.pricing {
  font-weight: 600 !important;
}

@media screen and (max-width: 1130px) {
  .market_info_container {
    width: 100%;
  }
}

@media screen and (max-width: 525px) {
  h3 {
    text-align: center;
  }

  .market_row {
    flex-direction: column;
  }

  .market_row p {
    margin: 0;
  }
}
