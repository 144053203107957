.dropdown{
  color: var(--secondary);
  background-color: rgb(255, 211, 193) !important;
}

.data_title {
  font-weight: bold;  
}

.sell_button {
  background-color: transparent;
  color: red;
  border: none;
}

.profit_loss {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .hide_tablet {
    display: none;
  }
}

@media screen and (max-width: 455px) {
  .hide_mobile {
    display: none;
  }

  .dropdown {
    font-size: 0.85rem;
  }
}
