.coin_search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.coin_search h2 {
  color:var(--primary);
  margin-right: 2rem;
  font-weight: 500;
}

@media screen and (max-width: 750px) {
  .coin_search {
    flex-direction: column;
  }

  .coin_search h2 {
    margin-right: 0;
  }

  .coin_search_input {
    margin-top: 1rem;
  }
}