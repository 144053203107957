/* .pie_chart_container {
  width: 100%;
} */

.pie_chart {
   width: 575px; 
 }

.no_chart {
   background-color: var(--primary);
   padding: 1rem 6rem;
   border-radius: 5px;
   text-align: center;
   color: var(--secondary);
   font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .pie_chart {
    width: 700px;
  }

}

@media screen and (max-width: 870px) {
  .pie_chart {
    width: 600px;
  }
}

@media screen and (max-width: 675px) {
  .pie_chart {
    width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .pie_chart {
    width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .pie_chart {
    width: 350px;
  }
}

@media screen and (max-width: 375px) {
  .pie_chart {
    width: 300px;
  }

}
@media screen and (max-width: 315px) {
  .pie_chart {
    width: 275px;
  }
}

