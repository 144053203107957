.td, .td:visited  {
  text-decoration: none;
  color: black; 
}

.coin_image {
  display: flex;
  align-items: center;
}

.coin_image img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}

.expand {
  text-align: center;
  color: var(--secondary);
  font-size: 1.25rem;
}

td .pricing_24h {
  display: flex;
}

.sell_button {
  background-color: red;
  border: none;
  outline: none;
  padding: .5em 2em;
  color: var(--secondary);
  letter-spacing: 2px;
}

@media screen and (max-width: 600px) {
  .hide_tablet {
    display: none;
  }
}

@media screen and (max-width: 455px) {
  .hide_mobile {
    display: none;
  }
}