.coin_info_container {
  color: white;
  flex: 1;
}

.coin_info_container h3 {
  font-size: 2rem;
  letter-spacing: 2px;
  margin-top: 3rem;
  font-weight: 500;
}

.coin_info {
  line-height: 2;
  font-weight: 300;
  margin-top: 1rem;
}

.coin_info_container a {
  color: var(--primary);
  text-decoration: none;
}

@media screen and (max-width: 525px) {
  .coin_info_container {
    padding: 0 .5rem;
  }
}