.portfolio_container {
  margin-top: 4rem;
  padding-bottom: 4rem;
  color: white;
}

.welcome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: groove;
  border-color: var(--primary);
  padding: 1rem;
}

.welcome h4 {  
  color: white;  
  font-size: 1.5rem;
}

.welcome span {
  color: var(--primary)
}

.balance_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.balance {
  color: var(--primary);
  font-size: 1.5rem;
}

.main {
  margin-top: 5rem;
}

.main__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.charts {
  flex: 5;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter select {
  outline: none;
  border: none;
  padding: .25em;
  margin-left: 1rem;
}

@media screen and (max-width: 1000px) {
  .main {
    gap: 200px;
  }
 
  .main__top {
    flex-direction: column-reverse;
    align-items: center;
    gap: 100px;
  }

  .charts {
    flex: 1;
  }
}

@media screen and (max-width: 575px) {
  .welcome {
    flex-direction: column;
    gap: 30px;
    border: none;
  }

  .main__top {
    gap: 75px;
  }
}