.nav {  
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8vh;
}

li {
  list-style: none;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo span, .logo p {
  font-size: 1.5rem;
}

.logo span {
  color: var(--primary);
  margin-left: .25rem;
}

.logo p {
  color: white;
  margin-right: .25rem;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--primary);
}

.nav_menu {
  display: flex;
  justify-content: space-around;
  align-items: center;   
  gap: 2rem;
}

.nav_item {
  width: 150px;
  text-align: center;
  padding: .5em;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  border: 1.5px solid var(--primary);  
  border-radius: 5px;
  letter-spacing: 2px;    
}

.logout_button {
  background-color: transparent;
  color: var(--primary);
  outline-color: var(--primary);
  border: none;
}

.login_button {
  background-color: var(--primary);
  color: var(--secondary);
  padding: .5em 2em;
}

.login_button:hover {
  background-color: var(--secondary);
  color: var(--primary);
  border: 2px solid var(--primary);  
}

.nav_toggler {
  display: none;
  cursor: pointer;
  color: var(--primary);
}

.nav_toggler div {
  width: 2rem;
  height: 2px;
  margin: .4rem 0;
  background-color: var(--primary);
  transition: 0.4s ease-in;
}


@media screen and (max-width: 780px) {
  .nav_toggler {
    display: block;
  }   

  .nav_menu {
    position: fixed;
    top: 8vh;
    right: 0;
    width: 100%;
    height: 92vh;
    z-index: 999;
    background-color: var(--secondary);
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    transform: translateX(100%);
    transition: 0.5s ease-in;    
  }

  .nav_item {
    border:  none;
    font-weight: 500;
  }

  .logout_button {
    font-size: 1rem;
    font-weight: 500;
  }

  .nav_active {
    transform: translateX(0);
  }

  .toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 8px);
  }

  .toggle .line2 {
    opacity: 0;
  }
  
  .toggle .line3 {
    transform: rotate(45deg) translate(-4px, -7px);
  }
}

@media screen and (max-width: 350px) {
  .logo p, .logo span{
    font-size: 1rem;
  }
}
