.coins_owned {
  margin-top: 3rem;
}

.coins_owned h4 {
  font-size: 1.25rem;
  color: var(--primary);
  letter-spacing: 2px;
  font-weight: 500;
}

.coins_owned_card {
  margin-top: 1rem;
  overflow: hidden;
  margin-top: 2rem;
}

.no_coins {
  text-align: center;
  font-weight: bold;
  background-color: var(--primary);
  color: var(--secondary);
  letter-spacing: 2px;
  padding: 1em;
}

@media screen and (max-width: 1000px) {
  .coins_owned h4 {
    text-align: center;
  }
}