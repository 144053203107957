.input {
  display: flex;
  flex-direction: column;  
  width: 100%;
  max-width: 375px;
}

.input label {
  font-size: .75rem;
}

.input input {
  width: 100%;  
  padding: .5rem;
  border-radius: 5px;
  border: 1px solid var(--secondary);
}

.input input:focus {
  outline-color: var(--primary);
}

.invalid {
  background-color: rgb(235, 145, 145);
}

.invalid_text {
  margin-top: 0.25rem;
  font-size: .75rem;
  text-align: left;
  color: rgb(255, 6, 6);
}


