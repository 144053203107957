.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary);
  opacity: .5;
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(218, 212, 212);
  overflow: hidden;
  z-index: 1000;
  border-radius: 10px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  height: max-content;
  max-width: 500px;
}

@media screen and (max-width: 550px) {
  .modal {
    border-radius: 0;
  }
}
