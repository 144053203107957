

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;    
  font-family: 'Poppins', sans-serif;  
}

html, body {
  font-weight: 400;
  font-size: 100%;
  line-height: 1;
  width: 100vw;
  min-height: 100vh;
}

body {
  --primary: rgba(242, 169, 0, 1);
  --secondary: rgba(33, 33, 33, 1);
  background-color: var(--secondary);  
  position: relative;
  overflow-x: hidden;
}

.app_container {
  width: 100%;
  max-width: 1600px;
  padding: 1rem 2rem;
  margin: 0 auto;  
  overflow-x: hidden;
}

.link {
  text-decoration: none;
}

@media screen and (max-width: 860px) {
  .app_container {
    padding: 1rem;
    }
}

@media screen and (max-width: 300px) {
  .app_container {
    padding: .5rem;
    }
}

