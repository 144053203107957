.login_card {    
  max-width: 450px;
  padding: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error_text {
  margin-top: 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: .75rem 1rem;
  margin-bottom: -2rem;
  background-color: rgb(235, 145, 145);
}

@media screen and (max-width: 500px) {
  .login_card {
    background-color: transparent; 
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}